import type { Client, RequestContext } from "../client";
export interface InAppMessagingUserPropertiesResource {
    UniqueId: string;
    UniqueIdHash: string;
    Username: string;
    Created: string;
    InstallationId: string;
    Version: string;
    DisplayName?: string;
    EmailAddress?: string;
    LicenseSerialNumberHash?: string;
}
export class InAppMessagingRepository {
    constructor(private readonly client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    getUserProperties(): Promise<InAppMessagingUserPropertiesResource> {
        return this.client.get<InAppMessagingUserPropertiesResource>(this.requestContext, "~/bff/messaging/userproperties");
    }
}
